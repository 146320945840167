import axios from 'axios';
import { getTokenCookie } from "./common/cookie";

const API_URL = 'https://api.mebook.ai/api';
// const API_URL = 'https://qa-api.mebook.ai/api';

const setTokenHeader = () => {
    axios.defaults.headers.common["Authorization"] = getTokenCookie();
};


export const getBook = async (id) => {
    setTokenHeader();
    const response = await axios.get(`${API_URL}/user/load?bookId=${id}`, {});
    return response.data.data;
};

export const getConfig = async (lang, clientId) => {
    const response = await axios.get(`${ API_URL }/frame/config-v2?lang=${ lang }&clientId=${ clientId }`);
    return response.data.data;
};
