import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getMostCommonColor } from "../../common/imageColors";
import ColorThief from "colorthief";
import { calculateFont } from "../../common/utils";

const WrapperStyled = styled.div`
    width: 100%;
    height: 100dvh;
    position: relative;
`;

const ImageStyled = styled.div`
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        height: 100%;
    }

    img {
        width: 100%;
        object-fit: contain;
    }
`;

const TextStyled = styled.div`
    position: absolute;
    bottom: 30dvh;
    text-align: center;
    width: 100%;
    text-shadow: 1px 1px 2px #000;
    font-family: 'Fredoka', sans-serif;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const TextWrapperStyled = styled.div`
    position: absolute;
`;


const ShowTimeItemCover = ({
                               page,
                               title,
                               coverDesign,
                               fontSize,
                               frameWidth,
                               frameHeight,
                               coverData
                           }) => {
    const imageRef = useRef(null);
    const [bgColors, s_bgColors] = useState("rbg(0,0,0)");
    const [imageActualHeight, s_imageActualHeight] = useState(0);
    const [imageActualWidth, s_imageActualWidth] = useState(0);
    const [imagePositionTop, s_imagePositionTop] = useState(0);
    const [imagePositionLeft, s_imagePositionLeft] = useState(0);

    useEffect(() => {
        const image = new Image();
        image.crossOrigin = "Anonymous"; // Enable CORS for the image

        image.onload = () => {
            const colorThief = new ColorThief();
            const color = colorThief.getColor(image);
            s_bgColors(`rgb(${ color[0] }, ${ color[1] }, ${ color[2] })`);
        };

        image.src = page.image;
    }, [page.image]);

    useEffect(() => {
        const updateImageSize = () => {
            if (imageRef?.current) {
                const parentWidth = imageRef.current.parentElement.offsetWidth;
                const parentHeight = imageRef.current.parentElement.offsetHeight;
                const imageAspectRatio = imageRef.current.naturalWidth / imageRef.current.naturalHeight;
                let imagePositionTop = (parentHeight - parentWidth / imageAspectRatio) / 2;
                let imagePositionLeft = (parentWidth - parentHeight * imageAspectRatio) / 2;

                if (imagePositionTop < 0) {
                    imagePositionTop = 0;
                }
                if (imagePositionLeft < 0) {
                    imagePositionLeft = 0;
                }

                s_imagePositionTop(imagePositionTop);
                s_imagePositionLeft(imagePositionLeft);


                let displayedWidth, displayedHeight;
                if (parentWidth / imageAspectRatio <= parentHeight) {
                    displayedWidth = parentWidth;
                    displayedHeight = parentWidth / imageAspectRatio;
                } else {
                    displayedWidth = parentHeight * imageAspectRatio;
                    displayedHeight = parentHeight;
                }

                s_imageActualHeight(displayedHeight);
                s_imageActualWidth(displayedWidth);
            }
        };

        if (imageRef?.current && imageRef.current.complete) {
            updateImageSize();
        } else {
            imageRef.current.onload = updateImageSize;
        }

        return () => {
            if (imageRef.current) {
                imageRef.current.onload = null;
            }
        };
    }, [imageRef]);

    let imageWidth = "100%";
    let imageHeight = "100%";
    if (frameWidth > frameHeight) {
        imageHeight = frameHeight;
    } else {
        imageWidth = frameWidth;
    }


    let textSize = fontSize / 10;
    let textTitle = title;
    let textColor = "#fff";
    let titlePosition = "10%";

    if (coverData?.textSize) {
        calculateFont(coverData.textSize, imageActualWidth);
    }
    if (coverData?.text) {
        textTitle = coverData.text;
    }
    if (coverData?.textColor) {
        textColor = coverData.textColor;
    }
    if (coverData?.position?.y !== undefined) {
        titlePosition = `${ coverData.position.y }%`;
    }

    return (
        <WrapperStyled
            style={ {
                backgroundColor: bgColors
            } }
        >
            <TextWrapperStyled
                style={ {
                    width: imageActualWidth,
                    height: imageActualHeight,
                    top: imagePositionTop,
                    left: imagePositionLeft
                } }
            >
                <TextStyled
                    style={ {
                        width: imageActualWidth,
                        fontSize: `${ textSize }px`,
                        color: textColor,
                        bottom: titlePosition
                    } }
                >
                    { textTitle }
                </TextStyled>
            </TextWrapperStyled>
            <ImageStyled>
                <img
                    ref={ imageRef }
                    style={ {
                        width: imageWidth,
                        height: imageHeight
                    } }
                    src={ page.image } alt="image page"/>
            </ImageStyled>
        </WrapperStyled>
    );
};

export default ShowTimeItemCover;
