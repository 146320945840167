import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Outlet, useLocation } from "react-router";
import { getTokenCookie } from "../common/cookie";
import { getConfig } from "../ApiCalls";
import Loader from "./components/Loader";

const MainWrapperStyled = styled.div`
    width: 100%;
    height: 100%;
`;

const ShowTimeWrapper = () => {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";
    const clientId = queryParameters.get("clientId") || "64d491633684ef0d32edb473";

    const [config, s_config] = useState(null);
    const [loading, s_loading] = useState(true);

    const token = getTokenCookie();

    useEffect(() => {
        if (clientId && !config) {
            getConfig(lang, clientId).then(data => {
                s_config(data);
            }).catch(e => {
                console.log("e", e);
            });
        }
    }, []);

    useEffect(() => {
        if (!clientId) {
            s_loading(false);
        }
    }, []);

    useEffect(() => {
        if (!token && config) {
            const frameUrl = config.frameUrl || "https://frame.mebook.ai";
            const currentUrl = window.location.href;
            window.location.href = `${ frameUrl }/otp?clientId=${ clientId }&language=${ lang }&returnUrl=${ encodeURIComponent(currentUrl) }`;
        } else {
            s_loading(false);
        }
    }, [token, config]);

    let classes = "lang-he";
    if (lang !== "he") {
        classes = "lang-en";
    }

    return (
        <MainWrapperStyled className={ classes }>
            { !loading && clientId && (
                <Outlet/>
            ) }
            { loading && clientId && (
                <Loader size={ 200 }/>
            ) }
            {!clientId && (
                <Loader size={ 200 }/>
            )}
        </MainWrapperStyled>
    );
};

export default ShowTimeWrapper;
